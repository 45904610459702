import * as yup from "yup";


export const loginSchema = yup.object({
  email: yup.string().email("Invalid email address").required("Email is required"),
  password: yup.string().matches().required("Password is required"),
  
})
.required();


export const addApplicantSchema = yup.object({
  first_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required("Please provide First Name"),
  last_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required("Please provide Last Name"),
  email: yup.string().matches( /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,'Invalid email address').required("Please provide Email"),
  phone: yup.string().matches(/^[6-9][0-9]{9}$/, "Phone must start with 9, 8, 7, or 6 and be exactly 10 digits").required("Please provide Phone"),
  address: yup.string().required("Please provide Address"),
  city: yup.string().required("Please provide City"),
  pincode: yup.string().required("Please provide Pincode"),
  zone: yup.string().required("Please provide Zone"),
  state: yup.string().required("Please provide State"),
})
.required();

export const addKinUserSchema = yup.object({
  first_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters')
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  })
  .max(50, 'First Name cannot exceed 50 characters')
  .required("Please provide First Name"),
  last_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters')
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  })
  .max(50, 'Last Name cannot exceed 50 characters')
  .required("Please provide Last Name"),
  email: yup.string().matches( /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,'Invalid email address').required("Please provide Email"),
  password: yup.string().test(
    'password-required',
    'Please provide a Password',
    function (value) {
      const { selectedId } = this.options.context;
      return selectedId || (!!value && value.trim() !== '');
    }
  ).min(8, "Password must be at least 10 characters long"),
  phone: yup.string().matches(/^[6-9][0-9]{9}$/, "Mobile number must start with 9, 8, 7, or 6 and be exactly 10 digits").required("Please provide Mobile Number"),
  address: yup.string().test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  }).max(80, "Address cannot exceed 80 characters").required("Please provide Address"),
  city_id: yup.string().required("Please provide City"),
  pin_code: yup.string().test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  }).matches(/^\d{6}$/, "Pincode must be exactly 6 digits").required("Please provide Pincode"),
  //pin_code: yup.string().matches(/^[0-9]{6}$/, "Enter valid pincode").required("Please provide Pincode"),
  zone_id: yup.string().required("Please provide Zone"),
  state_id: yup.string().required("Please provide State"),
  department_id: yup.string().required("Please provide Department"),
}).required();


export const addInnoceansUserSchema = yup.object({
  first_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters')
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  })
  .max(50, 'First Name cannot exceed 50 characters')
  .required("Please provide First Name"),
  last_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters')
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  })
  .max(50, 'Last Name cannot exceed 50 characters')
  .required("Please provide Last Name"),

  email: yup.string().matches( /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,'Invalid email address').required("Please provide Email"),
  password: yup.string().test(
    'password-required',
    'Please provide a Password',
    function (value) {
      const { selectedId } = this.options.context;
      return selectedId || (!!value && value.trim() !== '');
    }
  ).min(8, "Password must be at least 10 characters long"),
  phone: yup.string().matches(/^[6-9][0-9]{9}$/, "Mobile number must start with 9, 8, 7, or 6 and be exactly 10 digits").required("Please provide Mobile Number"),
  address: yup.string().test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  }).max(80, "Address cannot exceed 80 characters").required("Please provide Address"),
  city_id: yup.string().required("Please provide City"),
  pin_code: yup.string().matches(/^\d{6}$/, "Pincode must be exactly 6 digits").test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  }).required("Please provide Pincode"),
  zone_id: yup.string().required("Please provide Zone"),
  state_id: yup.string().required("Please provide State"),
}).required();

// export const addInnoceansUserSchema = yup.object({
//   first_name: yup.string().required("Please provide First Name"),
//   last_name: yup.string().required("Please provide Last Name"),
//   email: yup.string().email("Invalid email address").required("Please provide Email"),
//   password: yup.string().required("Please provide a Password").min(8, "Password must be at least 8 characters long"),
//   phone: yup.string().matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits").required("Please provide Phone Number"),
//   address: yup.string().required("Please provide Address"),
//   city_id: yup.string().required("Please provide City"),
//   pincode: yup.string().matches(/^[0-9]{6}$/, "Enter valid pincode").required("Please provide Pincode"),
//   zone_id: yup.string().required("Please provide Zone"),
//   state_id: yup.string().required("Please provide State"),
//   //role: yup.string().required("Please provide Role"), 
// }).required();

export const addGroupSchema = yup.object({
  dealer_group_name:yup.string()
  //.matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters')
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  })
  .max(50, 'Dealer Group Name cannot exceed 50 characters')
  .required("Please provide Dealer Group Name"),
  salutation: yup.string().required("Please provide Salutation"),
  dob: yup.string().required("Please provide Date Of Birth"),
  dp_name:yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  })
  .max(50, 'DP Name cannot exceed 50 characters')
  .required("Please provide DP Name"),
  dealer_priciple_email_address: yup.string().matches( /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,'Invalid email address').required("Please provide Dealer Principal Email Address"),
  dealer_principle_mobile_number: yup.string().matches(/^[6-9][0-9]{9}$/, "Mobile number must start with 9, 8, 7, or 6 and be exactly 10 digits").required("Please provide Mobile Number"),
  correspondence_address: yup
  .string()
  .required("Please provide Correspondence Address")
  .test(
    'no-leading-trailing-spaces',
    'No leading or trailing spaces allowed',
    (value) => value === value?.trim()
  ).max(250, 'Correspondence Address cannot exceed 250 characters'),
  //correspondence_address: yup.string().required("Please provide Address"),
  pin_code: yup.string().matches(/^[0-9]{6}$/, "Enter valid pincode").required("Please provide Pincode"),
  partner_type: yup.string().required("Please select Partner Type"),
  main_city: yup
  .string()
  .required("Please provide Main City")
  .test(
    'no-leading-trailing-spaces',
    'No leading or trailing spaces allowed',
    (value) => value === value?.trim()
  ).max(50, 'Main City cannot exceed 50 characters'),
  //main_city: yup.string().required("Please provide Main City"),
  business_background: yup.array()
      .of(yup.string()) // Define the type of array elements
      .min(1, "Please select at least one option for Business Background")
      .required("Please select at least one option for Business Background"),
  brands: yup.array()
      .of(yup.string()) // Define the type of array elements
      .min(1, "Please select at least one option for Brand")
      .required("Please select at least one option for Brand"),  
}).required(); 

const isEditMode = !!localStorage.getItem("selectedId");

export const addGroupOutletSchema =   yup.object({
  dealer_group_id: yup.string().required("Please provide Dealer Group"),
  master_code_DMS: yup.string().max(30, 'Master Code DMS cannot exceed 30 characters').notRequired(),
  main_dealer_code: yup.string().max(30, 'Master Dealer Code cannot exceed 30 characters').notRequired(),
  outlet_code: yup.string().max(30, 'Outlet Code cannot exceed 30 characters').notRequired(),
  trade_name: yup
  .string()
  .matches(/^[a-zA-Z0-9\s]+$/, 'Only (a-z, A-Z, 0-9) and spaces are allowed, no special characters').test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  })
  .max(50, "Trade Name cannot exceed 50 characters")
  .required("Please provide Trade Name"),
  region_id: yup.string().required("Please provide Region"),
  state_id: yup.string().required("Please provide State"),
  city_id: yup.string().required("Please provide City"),
  cluster_id: yup.string().required("Please provide Cluster"),
  location: yup.string().max(80, 'Location cannot exceed 80 characters').required("Please provide Location"),
  tier_id: yup.string().required("Please provide Tier"),
  outlet_type_id: yup.string().required("Please provide Outlet Type"),
  facility_type_id: yup.string().required("Please provide Facility Type"),
  project_type_showroom: yup.string().required("Please provide Project Type (Showroom)"),
  project_type_workshop: yup.string().required("Please provide Project Type (Workshop)"),
  ownership_showroom: yup.string().required("Please provide Ownership (Showroom)"),
  ownership_workshop: yup.string().required("Please provide Ownership (Workshop)"),
  // lease_expiry_date_showroom : yup.string().required("Please provide Lease Expiry Date (Showroom)"),
  // lease_expiry_date_workshop : yup.string().required("Please provide Lease Expiry Date (Workshop)"),
  //showroom_address: yup.string().required("Please provide Showroom Address"),
  showroom_address: yup.string().test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  }).max(250, "Showroom Address cannot exceed 250 characters").required("Please provide Showroom Address"),
  showroom_pincode: yup.string().test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  }).matches(/^\d{6}$/, "Showroom Pincode must be exactly 6 digits").required("Please provide Showroom Pincode"),
  showroom_longitude: yup.string()
  //.matches(/^-?\d+(\.\d+)?$/, 'Showroom Longitude must be a valid number, no special characters')
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  }).max(30, "Showroom Longitude cannot exceed 30 characters").required("Please provide Showroom Longitude"),
  showroom_latitude: yup.string()
  // .matches(/^-?\d+(\.\d+)?$/, 'Showroom Latitude must be a valid number, no special characters')
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  }).max(30, "Showroom Latitude cannot exceed 30 characters").required("Please provide Showroom Latitude"),
  workshop_address: yup.string().test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  }).max(250, "Workshop Address cannot exceed 250 characters").required("Please provide Workshop Address"),
  // workshop_address: yup.string().matches(/^[a-zA-Z0-9\s./]+$/, 'Only (a-z, A-Z, 0-9) and spaces are allowed, no special characters').test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
  //   return value === value?.trim();
  // }).max(80, "Workshop Address cannot exceed 80 characters").required("Please provide Workshop Address"),
  workshop_pincode: yup.string().matches(/^\d{6}$/, "Workshop Pincode must be exactly 6 digits").required("Please provide Workshop Pincode"),
  workshop_longitude: yup.string()
  //.matches(/^-?\d+(\.\d+)?$/, 'Workshop Longitude must be a valid number, no special characters')
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  }).max(30, "Workshop Longitude cannot exceed 30 characters").required("Please provide Workshop Longitude"),
  // workshop_longitude: yup.string().required("Please provide Workshop Longitude"),
  workshop_latitude: yup.string()
  //.matches(/^-?\d+(\.\d+)?$/, 'Workshop Latitude must be a valid number, no special characters')
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  }).max(30, "Workshop Latitude cannot exceed 30 characters").required("Please provide Workshop Latitude"),
  status: yup.string().required("Please provide Status"),
  appointment_date : yup.string().required("Please provide Appointment Date"),
  start_of_operation_target_date_showroom : yup.string().required("Please provide Date of Start of Operation Showroom"),
  start_of_operation_target_date_workshop : yup.string().required("Please provide Date of Start of Operation Workshop"),
  // closure_date_showroom : yup.string().required("Please provide Closure Date showroom"),
  // closure_date_workshop : yup.string().required("Please provide Closure Date workshop"),



  showroom_frontage_in_ft: yup.string().nullable().notRequired()
    .test('only-numbers', 'Only (0-9) and spaces are allowed, no special characters', (value) => {
      if (!value || value === "") return true; 
      return /^[0-9\s./]+$/.test(value); 
    })
    .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
      if (!value || value === "") return true;
      return value === value.trim();
    })
    .max(30, "Showroom Frontage cannot exceed 30 characters"),
  
  showroom_covered_area_in_sq_ft: yup.string().nullable().notRequired()
    .test('only-numbers', 'Only (0-9) and spaces are allowed, no special characters', (value) => {
      if (!value || value === "") return true; 
      return /^[0-9\s./]+$/.test(value); 
    })
    .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
      if (!value || value === "") return true;
      return value === value.trim();
    })
    .max(30, "Showroom Covered Area cannot exceed 30 characters"),
  
  showroom_plot_area_in_sq_ft: yup.string().nullable().notRequired()
    .test('only-numbers', 'Only (0-9) and spaces are allowed, no special characters', (value) => {
      if (!value || value === "") return true; 
      return /^[0-9\s./]+$/.test(value); 
    })
    .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
      if (!value || value === "") return true;
      return value === value.trim();
    })
    .max(30, "Showroom Plot Area cannot exceed 30 characters"),
  
  workshop_covered_area_in_sq_ft: yup.string().nullable().notRequired()
    .test('only-numbers', 'Only (0-9) and spaces are allowed, no special characters', (value) => {
      if (!value || value === "") return true; 
      return /^[0-9\s./]+$/.test(value); 
    })
    .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
      if (!value || value === "") return true;
      return value === value.trim();
    })
    .max(30, "Workshop Covered Area cannot exceed 30 characters"),
  
  workshop_plot_area_in_sq_ft: yup.string().nullable().notRequired()
    .test('only-numbers', 'Only (0-9) and spaces are allowed, no special characters', (value) => {
      if (!value || value === "") return true; 
      return /^[0-9\s./]+$/.test(value); 
    })
    .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
      if (!value || value === "") return true;
      return value === value.trim();
    })
    .max(30, "Workshop Plot Area cannot exceed 30 characters"),
  registered_company_name: yup.string().nullable()  // Allow null or undefined values
  .notRequired()  // Make the field optional
  .test('only-valid-characters', 'Only (a-z, A-Z, 0-9) and spaces are allowed, no special characters', (value) => {
    if (!value || value === "") return true;  // Skip validation if empty
    return /^[a-zA-Z0-9\s./]+$/.test(value);  // Ensure the value matches the pattern
  })
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    if (!value || value === "") return true;  // Skip validation if empty
    return value === value.trim();
  })
  .max(50, "Registered Company Name cannot exceed 50 characters"),
  CIN: yup
  .string()
  .nullable()
  .notRequired()
  .test('is-valid-cin', 'CIN must be exactly 21 characters long', (value) => {
    // If the field is null or empty, skip the validation and return true
    if (!value) return true; 
    // Otherwise, check for the 21-character length and valid characters
    return value.length === 21 && /^[A-Z0-9-]+$/.test(value);
  }),
  // CIN: yup
  // .string()
  // .nullable()
  // .notRequired()
  // .test('is-valid-cin', 'CIN must be exactly 21 characters long', (value) => {
  //   if (value === null || value === "") return true;
  //   return value.length === 21 && /^[A-Z0-9-]+$/.test(value);
  // }),
  registered_address: yup
  .string()
  .nullable()
  .notRequired()
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return !value || value === value.trim();
  })
  .max(80, "Registered Address cannot exceed 80 characters"),


  PAN: yup
  .string()
  .nullable()
  .notRequired()
  .test('valid-pan', 'PAN must be exactly 10 characters: 5 letters, 4 digits, and 1 letter at the end', (value) => {
    if (!value || value === "") return true;  
    return /^([A-Z]{5})(\d{4})([A-Z]{1})$/.test(value);
  }),
  GST: yup
  .string()
  .nullable()
  .notRequired()
  .test('valid-gst', 'GST number must be exactly 15 characters and follow the format: 2 digits, 4 letters, 4 digits, 1 letter, Z, and 1 alphanumeric character', (value) => {
    if (!value || value === "") return true;  
    return /^[0-9]{2}[A-Z]{4}[0-9]{4}[A-Z]{1}[Z]{1}[0-9A-Z]{1}$/.test(value);
  }),
  LOI_number : yup.string().nullable().notRequired().max(80, 'LOI number cannot exceed 80 characters'),
  LOA_number : yup.string().nullable().notRequired().max(80, 'LOA number cannot exceed 80 characters'),
  DA_number : yup.string().nullable().notRequired().max(80, 'DA number cannot exceed 80 characters'),
  // LOI_number: yup
  // .string()
  // .nullable() // Allow null values
  // .notRequired() // Make it optional
  // .test('valid-loi-number', 'LOI number must be exactly 50 characters long and can contain uppercase letters and numbers', (value) => {
  //   // Skip validation if the value is empty
  //   if (!value || value === "") return true;  
  //   // Check if the value matches the LOI format
  //   return /^[A-Z0-9]{50}$/.test(value);
  // }),
  // LOA_number: yup
  // .string()
  // .nullable() // Allow null values
  // .notRequired() // Make it optional
  // .test('valid-loa-number', 'LOA number must be exactly 50 characters long and can contain uppercase letters and numbers', (value) => {
  //   // Skip validation if the value is empty
  //   if (!value || value === "") return true;  
  //   // Check if the value matches the LOA format
  //   return /^[A-Z0-9]{50}$/.test(value);
  // }),
  // DA_number: yup
  //   .string()
  //   .nullable() // Allow null values
  //   .notRequired() // Make it optional
  //   .test('valid-da-number', 'DA number must be exactly 50 characters long and can contain uppercase letters and numbers', (value) => {
  //     if (!value || value === "") return true;  // Skip validation if empty
  //     return /^[A-Z0-9]{50}$/.test(value);
  //   }),
  facility_category: yup.array().of(yup.string().required()).required("Please provide Facility Category") 
}).required(); 


export const addSupplierUserSchema = yup.object({
  first_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters')
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  })
  .max(50, 'First Name cannot exceed 50 characters')
  .required("Please provide First Name"),
  last_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters')
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  })
  .max(50, 'Last Name cannot exceed 50 characters')
  .required("Please provide Last Name"),
  //first_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required('Please provide First Name '),
  //last_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required('Please provide Last Name '),
  email: yup.string().matches( /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,'Invalid email address').required("Please provide Email"),
  password: yup.string().test(
    'password-required',
    'Please provide a Password',
    function (value) {
      const { selectedId } = this.options.context;
      return selectedId || (!!value && value.trim() !== '');
    }
  ).min(8, "Password must be at least 8 characters long"),
  phone: yup.string().matches(/^[6-9][0-9]{9}$/, "Phone must start with 9, 8, 7, or 6 and be exactly 10 digits").required("Please provide Phone"),
  address: yup.string().required("Please provide Address"),
  city_id: yup.string().required("Please provide City"),
  pin_code: yup.string().matches(/^[0-9]{6}$/, "Enter valid pincode").required("Please provide Pincode"),
  zone_id: yup.string().required("Please provide Zone"),
  state_id: yup.string().required("Please provide State"),
  suppliercompany_id: yup.string().required("Please provide Supplier Company"),
}).required();


// export const addSupplierUserSchema = yup.object({
//   first_name: yup.string().required("Please provide First Name"),
//   last_name: yup.string().required("Please provide Last Name"),
//   email: yup.string().email("Invalid email address").required("Please provide Email"),
//   password: yup.string().required("Please provide a Password").min(8, "Password must be at least 8 characters long"),
//   phone: yup.string().matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits").required("Please provide Phone Number"),
//   address: yup.string().required("Please provide Address"),
//   city_id: yup.string().required("Please provide City"),
//   pin_code: yup.string().matches(/^[0-9]{6}$/, "Enter valid pincode").required("Please provide Pincode"),
//   zone_id: yup.string().required("Please provide Zone"),
//   state_id: yup.string().required("Please provide State"),
//   department_id: yup.string().required("Please provide Department"),
//   //role: yup.string().required("Please provide Role"), 
// }).required();

export const addUserRoleSchema = yup.object({
  usertype_name: yup.string().required("Please provide User Type"),
  name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  })
  .max(30, 'Role Name cannot exceed 30 characters')
  .required("Please provide Role Name"), 
  })
.required();

export const AddDepartmentSchema = yup.object({
  department_name: yup.string()
  // .matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters')
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  })
  .max(50, 'Department Name cannot exceed 50 characters')
  .required("Please provide Department Name"), 
}).required();

export const addOrganizationSchema = yup.object({
  name: yup.string()
    //.matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters')
    .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
      return value === value?.trim();
    })
    .max(50, 'Organization Name cannot exceed 50 characters')
    .required("Please provide Organization Name"),
  //is_direct: yup.string().required("Please provide Type of Organization"),
}).required();


export const categorySchema = yup.object({
    category: yup.string()
    //.matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters')
    .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
      return value === value?.trim();
    })
    .max(50, 'Category Name cannot exceed 50 characters')
    .required("Category Name is required"),
    snap: yup.mixed().required("Category image is required")
});

export const addInventorySchema = yup.object().shape({
  supplier_company_id: yup.lazy((value, { parent }) =>
    parent.supplier_id ? yup.string() : yup.string().required('Supplier Organization is required')
  ),
  supplier_id: yup.string(),
  category_id: yup.string().required('Material Category is required'),
  kia_specification: yup.string().test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  }).max(100, "Supplier Specification cannot exceed 100 characters").required("Supplier Specification is required"),
  unit_of_measurement: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only (a-z, A-Z) and spaces are allowed, no special characters').test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  }).max(30, "Unit of Measurement cannot exceed 30 characters").required("Unit of Measurement is required"),
  quantity: yup.number().transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    }).nullable().required('Quantity is required').positive('Quantity must be a positive number').integer('Quantity must be an integer')
    .max(9999999999, 'Quantity must be 10 digits or less')
    .typeError('Quantity must be a number'),
  supplier_price: yup
  .number()
  .transform((value, originalValue) => {
    return originalValue === "" ? null : value;
  })
  .nullable()
  .required('Supplier Price is required')
  .positive('Supplier Price must be a positive number')
  .max(9999999999, 'Supplier Price must be 10 digits or less')
  .test(
    'is-decimal',
    'Supplier Price can have up to 2 decimal places',
    (value) => value === undefined || /^\d+(\.\d{1,2})?$/.test(value.toString())
  )
  .typeError('Supplier Price must be a number'),

vendor_price: yup
  .number()
  .transform((value, originalValue) => {
    return originalValue === "" ? null : value;
  })
  .nullable()
  .required('Dealer Price is required')
  .positive('Dealer Price must be a positive number')
  .max(9999999999, 'Dealer Price must be 10 digits or less')
  .test(
    'is-decimal',
    'Dealer Price can have up to 2 decimal places',
    (value) => value === undefined || /^\d+(\.\d{1,2})?$/.test(value.toString())
  )
  .typeError('Dealer Price must be a number'),

  // supplier_price: yup.number().transform((value, originalValue) => {
  //   return originalValue === "" ? null : value;
  // }).nullable().required('Supplier Price is required').positive('Supplier Price must be a positive number').integer('Supplier Price must be an integer'),
  // vendor_price: yup.number().transform((value, originalValue) => {
  //   return originalValue === "" ? null : value;
  // }).nullable().required('Dealer Price is required').positive('Dealer Price must be a positive number').integer('Dealer Price must be an integer'),
  product_description: yup.string().test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  }).max(100, "Product Description cannot exceed 100 characters").required("Product Description is required"),
});

export const addRemarkSchema = yup.object().shape({
  remark: yup
  .string()
  .matches(/^[a-zA-Z0-9\s,\.]+$/, 'Only letters (a-z, A-Z), numbers (0-9), spaces, commas, and periods are allowed')
  .required('Please provide a remark'),
});

export const updateProfileSchema = yup.object({
  first_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only (a-z, A-Z) and spaces are allowed, no special characters').test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  }).max(15, "First Name cannot exceed 15 characters").required("Please provide First Name"),
  last_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only (a-z, A-Z) and spaces are allowed, no special characters').test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  }).max(15, "Last Name cannot exceed 15 characters").required("Please provide Last Name"),
  phone: yup.string().matches(/^[6-9][0-9]{9}$/, "Phone must start with 9, 8, 7, or 6 and be exactly 10 digits").required("Please provide Phone"),
}).required();

export const emailTemplateSchema = yup.object().shape({
  subject: yup
    .string()
    .required("Subject is required"),
  body: yup
    .string()
    .required("Body is required"),
  attachments: yup
    .array()
    .of(yup.mixed()) // Attachments can be any type (file, blob, etc.)
    .notRequired(),
});