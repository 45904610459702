import React, { useState, useEffect } from "react";
import Body from "../../components/Body";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useParams } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AttachmentAuth from "./AttachmentAuth";
import { useNavigate } from "react-router-dom";
import { editEmailTemplate, handleDeleteAttachment } from "../../store/dealerGroup/postAttachment";
import { useDispatch, useSelector } from "react-redux";
import { getInviteApi } from "../../store/dealerGroup/getInvite";
import { sendInvite } from "../../store/dealerGroup/sendInvite";
import { addInviteApi } from "../../store/dealerGroup/addInvite";
import style from "../../styles/buttons.module.scss";
import fieldsStyle from "../../styles/field.module.scss";
import { showToast } from "../../toast/toast";
import { getEmailTempleteId } from "../../store/dealerGroup/getEmailTempleteId";
import { convertDateFunctionYMD } from "../../utils/dateFormater";
import { emailTemplateSchema } from "../validationSchemas";
import { Label } from "@mui/icons-material";
import GroupIcon from '@mui/icons-material/Group';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
const PrevEdit = () => {
  const navigate = useNavigate();
  const [attachmentModal, setAttachmentModal] = useState(false);
  const { id } = useParams();
  const getEmailTempleteData = useSelector((state) => state?.getEmailTempleteIdReducer?.kinUserData?.data);
  const dispatch = useDispatch();
  const [fileState, setFileState] = useState([]);
  const postInvite = useSelector((state) => state?.addInviteReducer?.temp);
  const startDate = convertDateFunctionYMD(postInvite.start_date);
  const endDate = convertDateFunctionYMD(postInvite.end_date);
  const [body, setBody] = useState()
  const [subject, setSubject] = useState();
  const [attachment, setAttachment] = useState([]);

  const postInviteWithFormattedDates = {
    ...postInvite,
    start_date: startDate,
    end_date: endDate,
  };
  
  const handleAttchmetModal = () => {
    setAttachmentModal(true);
  };

  const theme = createTheme({
    palette: {
      secondary: {
        main: "#D3E1EF",
      },
    },
  });

  useEffect(() => {
    dispatch(getInviteApi());
  }, []);

  useEffect(() => {
    dispatch(getEmailTempleteId());
  }, []);

  useEffect(() => {
    if (getEmailTempleteData) {
      const emailData = getEmailTempleteData?.filter((i) => i?.id == id);
      setBody(emailData[0]?.body);
      setSubject(emailData[0]?.subject);
      setAttachment(emailData[0]?.attachments);

    }
  }, [getEmailTempleteData]);

  const handleSendInvite = async(getInviteId, type) => {
    const payload = { "invites_model_id": getInviteId }
   await dispatch(sendInvite(payload))
      .then((data) => {
        if (type == 2) {
          showToast(data?.payload?.message, 1);
        }

      })
      .catch((error) => {
        
      });
  }

  const handleEditTemplate = async() => {
    const validationData = {
      subject,
      body,
      attachments: fileState,
    };

    try {
      await emailTemplateSchema.validate(validationData, { abortEarly: false });
      const formData = new FormData();
      formData.append('subject', subject);
      formData.append('body', body);
  
      if (fileState.length > 0) {
        fileState.forEach((file) => {
          formData.append("attachments", file);
        });
      }
  
      const payload = { editTemplateId: id, formData };
  
      await dispatch(editEmailTemplate(payload))
        .then((data) => {
          dispatch(addInviteApi(postInviteWithFormattedDates)).then((data) => {
            let getInviteId = data.payload.data.id;
            handleSendInvite(getInviteId);
            showToast("Email sent successfully", 1);
          });
        })
        .catch((error) => {
          console.error("Error editing email template:", error);
        });
    } catch (validationError) {
      // Handle validation errors
      validationError.inner.forEach((err) => {
        showToast(err.message, 2); // Show each error message
      });
    }

    // const formData = new FormData();
    // if(!subject){
    //   return showToast("Please enter subject", 2)
    // }
    // if(!body){
    //   return showToast("Please enter body", 2)
    // }
    // formData.append('subject', subject);
    // formData.append('body', body);

    // if (fileState.length > 0) {
    //   fileState.forEach((file) => {
    //     formData.append("attachments", file);
    //   });
    // }

    // const payload = { "editTemplateId": id, formData };
    // await dispatch(editEmailTemplate(payload))
    //   .then((data) => {
    //      dispatch(addInviteApi(postInviteWithFormattedDates)).then((data) => {
    //       let getInviteId = data.payload.data.id;
    //       handleSendInvite(getInviteId);
    //       showToast("Email sent successfully", 1)

    //     })
    //   })
    //   .catch((error) => {
    //     console.error("Error editing email template:", error);
    //   });
  };

  const handleSaveInvites = async() => {
   await dispatch(addInviteApi(postInviteWithFormattedDates)).then((data) => {
      let getInviteId = data.payload.data.id;
      handleSendInvite(getInviteId);
      showToast(data?.payload?.status, 1);
    }).catch((error) => {
      showToast("Something went wrong", 1);
    });
  }

  const handleDeleteFileState = (filename) => {
    setFileState((prev) => prev.filter((file) => file.name !== filename));
  };

  const deleteAttachment = (attachmentId) => {
    const formData = new FormData();
    formData.append("attachments_to_remove", attachmentId);
    const payload = { editTemplateId: id, formData };
    dispatch(handleDeleteAttachment(payload))
      .then((data) => {
        showToast("Attachment deleted successfully", 1);
        setAttachment((prev) => prev.filter((file) => file.id !== attachmentId));
      })
      .catch((error) => {
        console.error("Error deleting attachment:", error);
      });
  };

  const [loading, setLoading] = useState(false);
  const useStyles = {
    buttonStyle: {
      py: 2,
      px: 4,
      fontSize: "16px",
      fontWeight: "600",
      boxShadow: "none",
    },
  };
  return (
    <>
  <Body>
    <Grid container alignItems="center" flexWrap="wrap" spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
          <Stack
              direction="row"
              spacing={1}
              sx={{ my: 2 }}
              alignItems="center"
          >
            <ForwardToInboxIcon/>
            <Typography variant="h6" className="pageTitle">Preview Invite Email</Typography>
          </Stack>
      </Grid>
    </Grid>
    <Grid container spacing={2} marginBlockStart={2}>
      <Grid item  xs={12}>
        <FormControl fullWidth margin="normal">
          <FormLabel>Subject</FormLabel>
        <TextField
          // label="Subject"
          variant="outlined"
          fullWidth
          multiline
          
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          error={!subject}
          helperText={!subject ? "Subject is required" : ""}
          sx={{ mt: "10px" }} // Add margin-bottom for spacing
        />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth margin="normal">
          <FormLabel>Body</FormLabel>
          <TextField
            // label="Body"
            variant="outlined"
            fullWidth
            multiline
            minRows={4}
            maxRows={6}
            value={body}
            onChange={(e) => setBody(e.target.value)}
            error={!body}
            helperText={!body ? "Body is required" : ""}
            sx={{ mt: "10px" }}
          />
        </FormControl>
      </Grid>
    </Grid>


    {attachment?.map((file) => (
      <Typography key={file.id} className={style.attachmentBtn}>
        {file?.file?.split("/").pop()}
        <CancelIcon onClick={() => deleteAttachment(file.id)} />
      </Typography>
    ))}

    {Array.isArray(fileState) &&
      fileState.map((item, index) => (
        <Typography key={index} className={style.attachmentBtn}>
          {item.name}
          <CancelIcon onClick={() => handleDeleteFileState(item.name)} />
        </Typography>
      ))}

    <Box component={"div"}>
      <Grid container py={6}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{ display: "flex", justifyContent: "start", gap: 2 }}
        >
          <Button
            variant="contained"
            sx={useStyles.buttonStyle}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            startIcon={<AttachFileIcon />}
            sx={useStyles.buttonStyle}
            onClick={handleAttchmetModal}
          >
            Attachment
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{ display: "flex", justifyContent: "end", gap: 2 }}
        >
          <Button
            variant="contained"
            disabled={loading}
            sx={useStyles.buttonStyle}
            onClick={() => {
              setLoading(true);
              handleEditTemplate().finally(() => setLoading(false));
            }}
          >
            {loading ? "Sending..." : "Send Invite"}
          </Button>
          <Button
            variant="contained"
            sx={useStyles.buttonStyle}
            onClick={handleSaveInvites}
          >
            Save Invite
          </Button>
        </Grid>
      </Grid>
    </Box>
  </Body>
  <AttachmentAuth
    open={attachmentModal}
    setOpen={setAttachmentModal}
    setFile={(filename) => setFileState(filename)}
  />
</>

  );
};
export default PrevEdit;
