import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Button, Box } from '@mui/material';
import { convertToDateFormatYearWithTwoDigits } from '../../utils/dateFormater';

const OpenCommnetViewModal = ({ open, handleClose, data }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Comment</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Feedback Received : {data?.comment}
                </DialogContentText>
                <DialogContentText>
                    Feedback On : {data?.feedback_on}
                </DialogContentText>
                <DialogContentText>
                    Added By : {data?.added_by}
                </DialogContentText>
                <DialogContentText>
                    Date : {convertToDateFormatYearWithTwoDigits(data?.updated_at)}
                </DialogContentText>
                <Box mt={2} display="flex" justifyContent="center">
                    {/* <Button onClick={handleClose} color="error" variant="contained" sx={{ mr: 1 }}>
                        Close
                    </Button> */}
                    <Button
                      variant="contained"
                      onClick={handleClose}
                      className={'btn black-btn'}
                    >
                      Close
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
export default OpenCommnetViewModal;
