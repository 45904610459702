import React, { useEffect } from "react";
import { Box, Tooltip, Typography, IconButton, Modal } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { handleDownload } from "./downloadFile";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import otherFile from "../../assets/img/file-icon.png";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ViewPaymentSlipModal({
  open,
  handleClose,
  PaymentSlip,
  UtrNo
}) {
  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleModal}>
        <IconButton
          onClick={() => handleClose()}
          sx={{ position: "absolute", top: "10px", right: "10px" }}
        >
          <HighlightOffIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2">
        Payment Slip
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Box>
            <p>UTR No. {UtrNo}</p>
            {PaymentSlip === null ? (
              <p>Payment not done</p>
            ) : (
              <>
                <Box key={PaymentSlip} className="text-center">
                  <Box className="p-relative">
                    <Tooltip
                      title={PaymentSlip}
                      placement="top"
                      className={` uploadedfilename`}
                    >
                      <Typography>{"Payment File"}</Typography>
                      <DownloadForOfflineRoundedIcon
                        className="my-auto"
                        onClick={() =>
                          handleDownload(
                            new Date() + "payment_slip",
                            PaymentSlip
                          )
                        }
                      />
                      <FullscreenIcon
                        className="my-auto"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_BASE_URL}${PaymentSlip}`
                          )
                        }
                      />
                    </Tooltip>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Typography>
      </Box>
    </Modal>
  );
}
