import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, Typography } from "@mui/material";
import { getFeedback } from '../../store/home/getFeedback';
import { useDispatch, useSelector } from 'react-redux';
import { convertToFormData } from '../ConvertToFormData';
import OpenCommnetViewModal from '../reusable/OpenCommentViewModal';
import { convertToDateFormatYearWithTwoDigits } from '../../utils/dateFormater';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function FeedbackReceived() {
    
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [allFeedbacks, setAllFeedbacks] = useState([]);
    const [isActive, setIsActive] = useState('Dealer')
    const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);
    const getInputDrawing = async () => {
        const formD = {
            'form_id': formId?.data?.id
        }
        const data = convertToFormData(formD);
        try {
            const response = await dispatch(getFeedback(data));
            setAllFeedbacks(response?.payload?.data);
            setData(response?.payload?.data?.filter(
                feedback => 
                    (feedback?.added_by === 'Dealer')
                ));
            //setData(response?.payload?.data);
        } catch (error) {
            
        }
    }

    const filterFeedback = (type) => {
        return () => {
            setIsActive(type)
            if(type==='toDealer'){
                setData(allFeedbacks?.filter(feedback => feedback?.added_for === 'Dealer'))
                return;
            }
            if(type==='toInn'){
                setData(allFeedbacks?.filter(feedback => feedback?.added_for === 'INN'))
                return;
            }

            setData(allFeedbacks?.filter(feedback => feedback?.added_by === type))
        }
    }

    const [openCommentViewModal, setOpenCommentViewModal] = useState(false);
    const [viewCommentData, setViewCommentData] = useState({});
    const openCommentView = (data) => {
        setOpenCommentViewModal(true);
        setViewCommentData(data);
    }  
    
    const closeCommentView = () => {
        setOpenCommentViewModal(false);
        setViewCommentData({});
    }
    
    useEffect(() => {
        getInputDrawing();
    }, []);

    return (
        <>
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Typography variant="h6" className="fs-17  fw-400">Feedbacks</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Box className="d-flex flex-wrap" sx={{ gap: "9px" }}>
                        <Button className= {isActive === 'Dealer' ? 'layout-blue-btn' : 'layout-lightblue-btn'}  onClick={filterFeedback('Dealer')}>
                            Feedback from Dealer
                        </Button>
                        
                        <Button className={isActive === 'toDealer' ? 'layout-blue-btn' : 'layout-lightblue-btn'} onClick={filterFeedback('toDealer')}>
                            Feedback To Dealer
                        </Button>
                        <Button className={isActive === 'Innocean' ? 'layout-blue-btn' : 'layout-lightblue-btn'} onClick={filterFeedback('Innocean')}>
                            Feedback From INN
                        </Button>
                        <Button className={isActive === 'toInn' ? 'layout-blue-btn' : 'layout-lightblue-btn'} onClick={filterFeedback('toInn')}>
                            Feedback To INN
                        </Button>

                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                    <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{ background: "#85949F !important",width:"170px" }} >Feedback Received</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important",width:"120px" }} align="center">Date</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important",width:"120px" }} align="center">Feedback On</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important",width:"170px" }} align="center">Added by</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((row) => (
                                    <StyledTableRow
                                    //  key={row.name}
                                    >
                                        <StyledTableCell component="th" scope="row">
                                            {row?.comment}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">{convertToDateFormatYearWithTwoDigits(row?.updated_at)}</StyledTableCell>
                                        <StyledTableCell align="center">{row?.feedback_on}</StyledTableCell>
                                        <StyledTableCell align="center">{row?.added_by}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Button className="DownloadBtn" onClick={() => openCommentView(row)}>View</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
        <OpenCommnetViewModal open={openCommentViewModal} handleClose={closeCommentView} data={viewCommentData} />
        </>
    );
}