import { Logout } from "@mui/icons-material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultProfileImg from "../assets/img/defaultProfileImg.jpg";
import Style from "../styles/appbar.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import { TbSitemap } from "react-icons/tb";
import { getUserDetailApi } from "../store/userProfile/getUserDetail";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { logoutApi } from "../store/auth/login";
import kialogo from "../../src/assets/img/kialogo.png"
import { isHandleDrawer } from "../store/drawer";
import { resetVerifyAuthOtp } from "../store/auth/verifyAuthOtp";
export default function Appbar() {
  const currentDealorSelector = useSelector((state) => state?.currentDealor);
  const { result } = currentDealorSelector;
  const getUserDetail = useSelector((state) => state?.getUserDetailReducer?.userDetailData?.result);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleUserMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCLoseUserMenu = () => setAnchorEl(null);
  const isMobile = useMediaQuery('(max-width:600px)');
  const handleLogout = async () => {
    dispatch(logoutApi())
    dispatch(resetVerifyAuthOtp())
    localStorage.clear();
    navigate("/login");
  };

  const handleUserProfile = async () => {
    navigate("/user/profile");
  };
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const handleMenuToggle = () => {
    const newState = !isMenuOpen;
    setIsMenuOpen(newState);
    dispatch(isHandleDrawer(newState))

  };
  React.useEffect(() => {
    dispatch(getUserDetailApi());
  }, [dispatch]);

  return (
    <Box className={Style.open}>
      <Stack
        sx={{ px: 2 }}
        width={"100%"}
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        spacing={1}
      >
      {isMobile ? (

        <MenuIcon onClick={handleMenuToggle} />

    ) : (
      <img width={50} src={kialogo} className={Style.mobilelogo}/>
    )}
        
        <Typography variant="h6" >
         
        </Typography>
        <Stack direction="row" alignItems="center">
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
            sx={{ cursor: "pointer", marginInlineStart: "0px !important" }}
          >
            <IconButton className={Style?.avatarButton}

            // sx={{ border: "1px solid #BCCBF3", padding: "0px" }}
            >
              <Avatar style={{ height: '35px', width: '35px' }} src={result?.profileImage || DefaultProfileImg} />
            </IconButton>
            <Stack spacing={1} onClick={() => setAnchorEl(true)}>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "12px", fontFamily: "'KiaSignatureRegular' !important", lineHeight: '1.3' }}
              >
                Super Admin
              </Typography>
              <Typography className={Style?.headerUserName}
                variant="body1"
              // sx={{
              //   fontSize: "14px",
              //   fontWeight: 700,
              //   marginTop: "0px !important",fontFamily: "'KiaSignatureBold' !important",lineHeight:'1.3',
              // }}
              >
                {getUserDetail?.first_name} {getUserDetail?.last_name}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      {/* user menu */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCLoseUserMenu}
        onClick={handleCLoseUserMenu}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{ mt: "45px" }}
      >
        
        <MenuItem onClick={handleUserProfile}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          My Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
}
