import React, { useEffect, useState } from "react";
import Body from "../../components/Body";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormGroup,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import Style from "../../styles/home.module.scss";
import FormStyle from "../../styles/field.module.scss"
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { convertToFormData } from "../ConvertToFormData";
import { addGroupSchema } from "../validationSchemas";
import { addDealerGroupApi, editDealerGroupApi } from "../../store/dealerGroup/addGroup";
import { getGroupDealerApi } from "../../store/dealerGroup/getGroupDealer";
import buttonStyles from "../../styles/buttons.module.scss";
import { convertDateFunctionYMD } from "../../utils/dateFormater";
import { showToast } from "../../toast/toast";
import { brand, businessBackground } from "../reusable/common";


function AddGroup() {
    const getApplicant = useSelector((state) => state?.getGroupDealorSliceReducer?.result?.data);
    const addApplicantDataRedux = useSelector((state) => state?.addDealerGroupReducer)
    const { loading } = addApplicantDataRedux;
    const [addGroupRequest, setAddGroupRequest] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const initialSelectedValues = [];
    const [selectedValues, setSelectedValues] = useState(initialSelectedValues);
    const handleChangeBusinessGroup = (event) => {
        const value = event.target.value;
        const isChecked = event.target.checked;

        setSelectedValues((prevSelectedValues) =>
            event.target.checked
                ? [...prevSelectedValues, value]
                : prevSelectedValues.filter((item) => item !== value)
        );

        updateError('business_background')
        setCheckboxErrors((prevErrors) => ({
            ...prevErrors,
            business_background: isChecked && selectedValues.length === 0 ? "Please select at least one option for Business Background" : ""
        }));

    };

    useEffect(() => {

        if (selectedValues.length > 0) {
            setAddGroupRequest((preValue) => {
                return {
                    ...preValue,
                    'business_background': selectedValues
                }
            })
        }
        setCheckboxErrors((prevErrors) => ({
            ...prevErrors,
            business_background: selectedValues.length === 0 ? "Please select at least one option for Business Background" : ""
        }));

    }, [selectedValues])

    const initialSelectedValuesBrand = [];
    const [selectedValuesBrand, setSelectedValuesBrand] = useState(initialSelectedValuesBrand);

    const handleChangeBrand = (event) => {
        const value = event.target.value;
        const isChecked = event.target.checked;

        setSelectedValuesBrand((prevSelectedValues) =>
            isChecked
                ? [...prevSelectedValues, value]
                : prevSelectedValues.filter((item) => item !== value)
        );
        updateError('brands')
        setCheckboxErrors((prevErrors) => ({
            ...prevErrors,
            brands: isChecked && selectedValuesBrand.length === 0 ? "Please select at least one option for Brand" : ""
        }));
    };

    useEffect(() => {
        if (selectedValuesBrand.length > 0) {
            setAddGroupRequest((preValue) => {
                return {
                    ...preValue,
                    'brands': selectedValuesBrand
                }
            })
        }
        setCheckboxErrors((prevErrors) => ({
            ...prevErrors,
            brands: selectedValuesBrand.length === 0 ? "Please select at least one option for Brand" : ""
        }));
    }, [selectedValuesBrand])


    useEffect(() => {
        if (dateOfBirth !== null) {
            setAddGroupRequest((preValue) => {
                return {
                    ...preValue,
                    'dob': convertDateFunctionYMD(dateOfBirth)
                }
            })
        }

    }, [dateOfBirth])

    const addRequestAPI = async () => {

        const data = convertToFormData(addGroupRequest)

        const addGroupResponse = await dispatch(addDealerGroupApi(data));
        if (addGroupResponse?.payload?.status === "error") {
            showToast(addGroupResponse?.payload?.message, 2);
            return;
        }
        showToast(addGroupResponse?.payload?.status, 1)
        dispatch(getGroupDealerApi());
        navigate('/users/dealer')
    }
    const editRequestAPI = async () => {
        const data = convertToFormData(addGroupRequest)
        const addGroupResponse = await dispatch(editDealerGroupApi(data));
        if (addGroupResponse?.payload?.status === "error") {
            showToast(addGroupResponse?.payload?.message, 2);
            return;
        }
        showToast("Dealer Group updated successfully", 1)
        dispatch(getGroupDealerApi());
        navigate('/users/dealer')
    }
    const handleChange = (event) => {
        const { name, value, } = event.target;
        setAddGroupRequest(prevState => ({
            ...prevState,
            [name]: value
        }));
        updateError(name)
    };
    const updateError = (name) => {
        setErrors((preValue) => {
            return {
                ...preValue,
                [name]: ""
            }
        })
    }


    useEffect(() => {
        if (localStorage.getItem("selectedId") && getApplicant) {
            const EditData = localStorage.getItem("selectedId")
                ? getApplicant.filter((row) => row.id == localStorage.getItem('selectedId'))
                : [];

            if (EditData && EditData.length > 0) {
                setAddGroupRequest({
                    'dealer_group_name': EditData[0].dealer_group_name,
                    'salutation': EditData[0].salutation,
                    'dp_name': EditData[0].dp_name,
                    'dob': EditData[0].dob,
                    'dealer_principle_mobile_number': EditData[0].dealer_principle_mobile_number,
                    'dealer_priciple_email_address': EditData[0].dealer_priciple_email_address,
                    'correspondence_address': EditData[0].correspondence_address,
                    'pin_code': EditData[0].pin_code,
                    'partner_type': EditData[0].partner_type,
                    'main_city': EditData[0].main_city,
                });
                setSelectedValues(EditData[0].business_background || []);
                setSelectedValuesBrand(EditData[0].brands || []);
            }
        }
    }, [localStorage.getItem("selectedId"), getApplicant]);

    useEffect(() => {
        if (!getApplicant)
            dispatch(getGroupDealerApi())
    }, [getApplicant]);

    const [errors, setErrors] = useState({});
    const [checkboxErrors, setCheckboxErrors] = useState({});
    const handleSubmit = (e) => {
        e.preventDefault();
        const mergedErrors = { ...errors, ...checkboxErrors };
        addGroupSchema.validate(addGroupRequest, { abortEarly: false })
            .then(() => {
                if (localStorage?.getItem("selectedId") && localStorage.getItem("selectedId") !== undefined && localStorage.getItem("selectedId") !== "") {
                    editRequestAPI()
                } else {
                    addRequestAPI();
                }
                setErrors({});
            })
            .catch((validationErrors) => {
                const newErrors = {};
                validationErrors.inner.forEach((error) => {
                    newErrors[error.path] = error.message;
                });
                setErrors({ ...mergedErrors, ...newErrors });
                showToast("Please fill mandatory fields", 2)
            });
    };
    return (
        <>
            <Body>
                <span className={buttonStyles.arrowBack} onClick={() => navigate(-1)}></span>
                <form onSubmit={handleSubmit}>
                    <Box className={Style?.headerSection}>
                        <Typography
                            color="#fff"
                            fontWeight={500}
                            variant="h6"
                            marginInlineStart={3}
                        >
                            Group Information
                        </Typography>
                    </Box>
                    <Grid container marginBlockStart={2} spacing={2}>
                        <Grid item xs={12} sx={{ marginBlockEnd: "30px" }}>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Dealer Group Name <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                    <TextField
                                        id="input"
                                        variant="outlined"
                                        value={addGroupRequest?.dealer_group_name}
                                        name="dealer_group_name"
                                        onChange={handleChange}
                                        fullWidth
                                        sx={{ marginTop: "10px" }}
                                        error={Boolean(errors.dealer_group_name)}
                                        helperText={errors.dealer_group_name}
                                    />
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Salutation <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            id="demo-simple-select"
                                            value={addGroupRequest?.salutation ? addGroupRequest?.salutation : ""}
                                            onChange={handleChange}
                                            name="salutation"
                                            sx={{ marginTop: "10px" }}

                                        >
                                            <MenuItem value="">Select Salutation</MenuItem>
                                            <MenuItem value="Mr.">Mr.</MenuItem>
                                            <MenuItem value="Mrs.">Mrs.</MenuItem>
                                            <MenuItem value="Ms.">Ms.</MenuItem>
                                        </Select>
                                        <FormHelperText error>
                                            {errors?.salutation}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        DP Name <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="input"
                                            variant="outlined"
                                            value={addGroupRequest?.dp_name}
                                            name="dp_name"
                                            onChange={handleChange}
                                            fullWidth
                                            sx={{ marginTop: "10px" }}
                                            error={Boolean(errors.dp_name)}
                                            helperText={errors.dp_name}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Date of Birth <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={["DatePicker"]}>
                                            <DatePicker
                                                name="dob"
                                                maxDate={dayjs()}
                                                value={addGroupRequest?.dob ? dayjs(addGroupRequest?.dob) : ""}
                                                slotProps={{ textField: { fullWidth: true } }}
                                                onChange={(newValue) => {
                                                    setDateOfBirth(newValue)
                                                    updateError('dob')
                                                }
                                                }

                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    <FormHelperText error>
                                        {errors?.dob}
                                    </FormHelperText>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Dealer Principal Mobile Number <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                    <TextField
                                        id="input"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ marginTop: "10px" }}
                                        value={addGroupRequest?.dealer_principle_mobile_number}
                                        name="dealer_principle_mobile_number"
                                        onChange={handleChange}
                                        error={Boolean(errors.dealer_principle_mobile_number)}
                                        helperText={errors.dealer_principle_mobile_number}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Dealer Principal Email Address <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                    <TextField
                                        id="input"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ marginTop: "10px" }}
                                        value={addGroupRequest?.dealer_priciple_email_address}
                                        onChange={handleChange}
                                        name="dealer_priciple_email_address"
                                        error={Boolean(errors.dealer_priciple_email_address)}
                                        helperText={errors.dealer_priciple_email_address}
                                    // Add any other TextField props as needed
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Correspondence Address <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                    <TextField
                                        id="input"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ marginTop: "10px" }}
                                        value={addGroupRequest?.correspondence_address}
                                        onChange={handleChange}
                                        name="correspondence_address"
                                        error={Boolean(errors.correspondence_address)}
                                        helperText={errors.correspondence_address}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Pin Code <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                    <TextField
                                        id="input"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ marginTop: "10px" }}
                                        value={addGroupRequest?.pin_code}
                                        onChange={handleChange}
                                        name="pin_code"
                                        //inputProps={{ maxLength: 6, minLength: 6 }}
                                        error={Boolean(errors.pin_code)}
                                        helperText={errors.pin_code}
                                    />
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Partner Type <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            id="demo-simple-select"
                                            value={addGroupRequest?.partner_type ? addGroupRequest?.partner_type : ""}
                                            onChange={handleChange}
                                            name="partner_type"
                                            sx={{ marginTop: "10px" }}
                                        >
                                            <MenuItem value="">Select Partner</MenuItem>
                                            <MenuItem value="New">New</MenuItem>
                                            <MenuItem value="existing">Existing</MenuItem>
                                        </Select>
                                        <FormHelperText error>
                                            {errors?.partner_type}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Main City <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                    <TextField
                                        id="input"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ marginTop: "10px" }}
                                        value={addGroupRequest?.main_city}
                                        name="main_city"
                                        onChange={handleChange}
                                        error={Boolean(errors.main_city)}
                                        inputProps={{ maxLength: 50 }}
                                        helperText={errors.main_city}
                                    />
                                    {/* <FormHelperText error>
                                            {errors?.main_city}
                                        </FormHelperText> */}
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Business Background <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                    <FormGroup className={FormStyle.checkboxGroup}>
                                        {businessBackground?.map(option => (
                                            <div key={option} className={FormStyle.options}>

                                                <Checkbox
                                                    // error={Boolean(errors.business_background)}
                                                    // helperText={errors.business_background}
                                                    name="business_background"
                                                    value={option}
                                                    checked={selectedValues.includes(option)}
                                                    onChange={handleChangeBusinessGroup}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />{option}<br />

                                            </div>
                                        ))}
                                    </FormGroup>
                                    <FormHelperText error>
                                        {errors?.business_background}
                                    </FormHelperText>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Brand <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                    <FormGroup className={FormStyle.checkboxGroup}>
                                        {brand?.map(option => (
                                            <div key={option} className={FormStyle.options}>

                                                <Checkbox
                                                    name="brands"
                                                    value={option}
                                                    checked={selectedValuesBrand.includes(option)}
                                                    onChange={handleChangeBrand}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />{option}<br />
                                            </div>
                                        ))}

                                    </FormGroup>
                                    <FormHelperText error>
                                        {errors?.brands}
                                    </FormHelperText>
                                </Grid>
                                {/* <Grid item md={6} xs={12}>
                                    <Typography  gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Other 2
                                    </Typography>
                                    <TextField
                                        id="input"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ marginTop: "10px" }}
                                        value={addGroupRequest?.group_code}
                                        name="group_code"
                                        onChange={handleChange}
                                    />
                                </Grid> */}
                                {/* <Grid item md={6} xs={12}>
                                    <Typography gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Other 3
                                    </Typography>
                                    <TextField
                                        id="input"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ marginTop: "10px" }}
                                        value={addGroupRequest?.cluster_group}
                                        name="cluster_group"
                                        onChange={handleChange}
                                    />
                                </Grid> */}
                                {/* <Grid item md={6} xs={12}>
                                    <Typography gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Other 4
                                    </Typography>
                                    <TextField
                                        id="input"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ marginTop: "10px" }}
                                        value={addGroupRequest?.trade_name}
                                        name="trade_name"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Typography gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Other 5
                                    </Typography>
                                    <TextField
                                        id="input"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ marginTop: "10px" }}
                                        value={addGroupRequest?.loi_status}
                                        name="loi_status"
                                        onChange={handleChange}
                                    />
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container marginBlockStart={2} spacing={2} justifyContent="flex-end">
                        <Grid item xs={6} sx={{ marginBlockEnd: "30px" }} style={{ textAlign: 'right' }}>
                            <Button
                            type="submit"
                                disabled={loading}
                                // fullWidth
                                className="black-btn"
                            >
                                {loading ? <CircularProgress sx={{ color: "#ffffff" }} /> : "Submit"}{" "}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Body>
        </>
    );
}

export default AddGroup;
