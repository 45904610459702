import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

    export const getGroupDealerApi = createAsyncThunk(
        'groupDealor/get-group-dealer',
        async (_, { rejectWithValue }) => {
            try {
                const response = await AuthAxios.get(`/api/v1/dealor/dealer-group`,
                    { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
                )
                return response?.data
            } catch (error) {
                return rejectWithValue(error?.response?.data)
            }
        }
    )

    export const groupToggleActive = createAsyncThunk(
        'groupDealor/toggle-user_status',
        async (formData, { rejectWithValue }) => {
            try {
                const response = await AuthAxios.post(`/api/v1/kin_user/kinuser_status/`,formData,
                    { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
                )
                return response?.data
            } catch (error) {
                return rejectWithValue(error?.response?.data)
            }
        }
    )

    export const downloadDealerGroupData = createAsyncThunk(
        'dealerGroup/downloadDealerGroupData',
        async (ids, { rejectWithValue }) => {
            try {
                const response = await AuthAxios.get('/api/v1/super_admin/downloaddealergroup/', {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },
                    params: { ids: ids.join(',') },
                    responseType: 'blob', // Ensures the response is treated as a binary file
                });
    
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
    
                const contentDisposition = response.headers['content-disposition'];
                const filename = contentDisposition
                    ? contentDisposition.split('filename=')[1].replace(/"/g, '')
                    : 'dealerGroup.xlsx';
    
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
    
                return true; 
            } catch (error) {
                return rejectWithValue(error.response?.data || 'An error occurred');
            }
        }
    );

const getGroupDealorSlice = createSlice({
    name: 'groupDealer',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(getGroupDealerApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getGroupDealerApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(getGroupDealerApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })

        builder.addCase(groupToggleActive.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(groupToggleActive.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(groupToggleActive.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        }) 
        builder.addCase(downloadDealerGroupData.pending, () => {
            console.log("Download request started");
        });
        builder.addCase(downloadDealerGroupData.fulfilled, (state, action) => {
            console.log("Download completed successfully", action.payload);
            // You could trigger side effects here if needed.
        });
        builder.addCase(downloadDealerGroupData.rejected, (state, action) => {
            console.error("Download failed", action.payload);
        });   
          
    },
})

export const getGroupDealorSliceReducer = getGroupDealorSlice.reducer